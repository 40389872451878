<script setup lang="ts">
import type { InputHTMLAttributes } from 'vue';
import InputStyle from '~theme/components/atoms/styles/InputStyle.vue';

withDefaults(
  defineProps<{
    invalid?: boolean;
    disabled?: InputHTMLAttributes['disabled'];
    modelValue?: InputHTMLAttributes['value'];
    placeholder?: InputHTMLAttributes['placeholder'];
    type?: InputHTMLAttributes['type'];
    inputmode?: InputHTMLAttributes['inputmode'];
  }>(),
  {
    disabled: false,
    invalid: false,
    modelValue: '',
    placeholder: '',
    type: 'text',
    inputmode: 'text',
  }
);

const emits = defineEmits<{
  'update:modelValue': [value: string];
  clear: [];
  focus: [event: FocusEvent];
  blur: [event: FocusEvent];
}>();

const ref = useTemplateRef('input');
defineExpose({ ref });

const onInput = (event: Event) => {
  const value = event.target ? (event.target as HTMLInputElement).value : '';
  if (!value.length) {
    emits('clear');
  }

  emits('update:modelValue', value);
};
</script>

<template>
  <InputStyle :invalid="invalid" :disabled="disabled">
    <input
      ref="input"
      :type="type"
      :value="modelValue"
      :placeholder="placeholder"
      :inputmode="inputmode"
      :disabled="disabled"
      v-bind="$attrs"
      @input="onInput"
      @focus="emits('focus', $event)"
      @blur="emits('blur', $event)"
    />
  </InputStyle>
</template>
